// Auto-generated file: Do not modify directly. Changes may be overwritten. Version: 1.2.0.

/*
 * Following tokens have been deprecated in version 1.2.0, please update your theme:
 *
 * Interactive/Focus has been split into two separate tokens. Please define 'Interactive/Focus Inner' and 'Interactive/Focus Outer' instead.
 * 'Interactive/Background Default' is deprecated. Please use 'Input/Background Default' instead.
 * 'Interactive/Background Disabled' is deprecated. Please use 'Input/Background Disabled' instead.
 * 'Interactive/Background Hovered' is deprecated. Please use 'Input/Background Hovered' instead.
 * 'Interactive/Background Pressed' is deprecated. Please use 'Input/Background Pressed' instead.
 * 'Interactive/Border Default' is deprecated. Please use 'Input/Border Default' instead.
 * 'Interactive/Border Hovered' is deprecated. Please use 'Input/Border Hovered' instead.
 * 'Interactive/Border Disabled' is deprecated. Please use 'Input/Border Disabled' instead.
 * 'Interactive/Border Critical Default' is deprecated. Please use 'Input/Border Critical' instead.
 * 'Pill' is deprecated. Please use 'Circle' instead.
 */

import type { GDSTheme } from '@leagueplatform/genesis-core';

export const manulifeTheme: GDSTheme = {
  colors: {
    surface: {
      background: {
        primary: '#FFFFFFFF',
        secondary: '#FAFAFAFF',
        tertiary: '#EDEDEDFF',
      },
      card: {
        primary: '#FFFFFFFF',
        secondary: '#FAFAFAFF',
        disabled: '#DFE0E2FF',
      },
      screenHeader: { background: '#DEF3FCFF' },
      skeleton: {
        shimmer: {
          background: '#E7E7E7FF',
          overlay:
            'linear-gradient(90.0deg, #FFFFFF00 0%, #FFFFFF80 50%, #FFFFFFE5 70%, #FFFFFF00 100%)',
        },
      },
    },
    onSurface: {
      text: {
        primary: '#282B3EFF',
        subdued: '#5E6073FF',
        reversed: '#FFFFFFFF',
        critical: '#DB1F00FF',
        success: '#06874EFF',
        warning: '#F49600FF',
      },
      border: { subdued: '#EDEDEDFF', default: '#DDDEE4FF', card: '#EDEDEDFF' },
      icon: { primary: '#282B3EFF' },
    },
    interactive: {
      action: {
        primary: '#046138FF',
        hovered: '#004427FF',
        pressed: '#004427FF',
        subdued: '#66686BFF',
        disabled: '#C2C3C9FF',
      },
      icon: { default: '#282B3EFF', disabled: '#DDDEE4FF' },
      background: {
        default: '#FFFFFFFF',
        disabled: '#DFE0E2FF',
        hovered: '#F8F8F8FF',
        pressed: '#F0F0F0FF',
      },
      border: {
        default: '#949494FF',
        hovered: '#046138FF',
        disabled: '#D6D6D6FF',
        critical: { default: '#DB1F00FF' },
      },
      focus: { inner: '#DFE0E2FF', outer: '#06874EFF' },
    },
    critical: {
      background: { subdued: '#F6CCC7FF', secondary: '#FFFFFFFF' },
      border: { default: '#DB1F00FF' },
      icon: '#DB1F00FF',
    },
    warning: {
      background: { subdued: '#F8E9C6FF', secondary: '#FFFFFFFF' },
      border: { default: '#A75900FF' },
      icon: '#F49600FF',
    },
    success: {
      background: { subdued: '#8F90A2FF', secondary: '#CAEED9FF' },
      border: { default: '#4C336BFF' },
      icon: '#06874EFF',
    },
    highlight: {
      background: { subdued: '#EDEDEDFF', secondary: '#FFFFFFFF' },
      border: { default: '#2E5CDFFF' },
      icon: '#2D69FFFF',
    },
    decorative: {
      brand: {
        primary: {
          darkest: '#261848FF',
          dark: '#4C336BFF',
          default: '#6F5693FF',
          bright: '#9D8DBCFF',
          brightest: '#D4D2E9FF',
          pastel: '#F5F5F5FF',
        },
        secondary: {
          darkest: '#0B9189FF',
          dark: '#05B2A7FF',
          default: '#06C7BAFF',
          bright: '#68E7DFFF',
          brightest: '#9DF3EDFF',
          pastel: '#C5F4F1FF',
        },
        tertiary: {
          darkest: '#845A09FF',
          dark: '#C2891BFF',
          default: '#EDEDEDFF',
          bright: '#F5C464FF',
          brightest: '#FFE4B0FF',
          pastel: '#FFF8EAFF',
        },
      },
    },
    primary: {
      background: {
        default: '#EC6453FF',
        hovered: '#D03A39FF',
        pressed: '#A00E18FF',
        critical: {
          default: '#DB1F00FF',
          hovered: '#DB1F00FF',
          pressed: '#DB1F00FF',
        },
      },
      text: { default: '#FFFFFFFF', critical: { default: '#FFFFFFFF' } },
    },
    secondary: {
      background: {
        default: '#FFFFFF00',
        hovered: '#D03A39FF',
        pressed: '#A00E18FF',
      },
      text: { default: '#EC6453FF', hovered: '#FFFFFFFF' },
      border: { default: '#EC6453FF' },
    },
    tertiary: {
      background: {
        default: '#FFFFFF00',
        hovered: '#046138FF',
        pressed: '#004427FF',
      },
      text: { default: '#00A758FF', hovered: '#FFFFFFFF' },
      border: { default: '#00A758FF' },
    },
    input: {
      border: {
        success: '#4C336BFF',
        warning: '#F49600FF',
        default: '#949494FF',
        hovered: '#046138FF',
        disabled: '#D6D6D6FF',
        critical: '#DB1F00FF',
      },
      background: {
        default: '#FFFFFFFF',
        disabled: '#DFE0E2FF',
        hovered: '#F8F8F8FF',
        pressed: '#F0F0F0FF',
      },
    },
    dataViz: {
      interface: { track: { border: '#959595FF', background: '#F5F5F5FF' } },
      singleColor: { brand: '#008BCCFF', neutral: '#959595FF' },
      status: {
        critical: '#CB2A32FF',
        warning: '#DE6F12FF',
        success: '#159369FF',
      },
      categorical: {
        one: '#008BCCFF',
        two: '#074664FF',
        three: '#00699AFF',
        four: '#C2891BFF',
        five: '#845A09FF',
        six: '#008B82FF',
      },
    },
  },
  shadows: {
    card: { x: 0, y: 0, blur: 0, spread: 0, color: '#00000000' },
    dropdown: { x: 0, y: 4, blur: 8, spread: 0, color: '#0000001A' },
  },
  typography: {
    headingOne: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 300,
      lineHeight: 54,
      fontSize: 44,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingTwo: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 44,
      fontSize: 32,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingThree: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 32,
      fontSize: 22,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingFour: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 26,
      fontSize: 18,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    subtitleOne: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 26,
      fontSize: 18,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    subtitleTwo: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyOne: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyOneSecondary: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyTwo: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyTwoSecondary: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    buttonOne: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 24,
      fontSize: 19,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    buttonTwo: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    label: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    overline: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    caption: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 4,
      textCase: 'none',
      textDecoration: 'none',
    },
    tab: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 12,
      fontSize: 10,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
  },
  spacing: {
    none: 0,
    quarter: 4,
    half: 8,
    threeQuarters: 12,
    one: 16,
    oneAndHalf: 24,
    two: 32,
    twoAndHalf: 40,
    three: 48,
    four: 64,
    five: 80,
  },
  borderWidths: { thin: 1, thick: 2, outerFocus: 3, innerFocus: 3, card: 1 },
  borderRadii: {
    none: 0,
    thin: 1,
    thick: 2,
    small: 3,
    medium: 6,
    large: 9,
    extraLarge: 12,
    huge: 20,
    circle: 99999,
    pill: 3,
    button: 0,
    inputField: 0,
    card: 0,
  },
  zIndices: {
    default: 1,
    sticky: 100,
    dropdown: 4000,
    tooltip: 5000,
    modal: 8000,
    toast: 9000,
  },
  rem: {
    fontSizes: {
      headingOne: 2.75,
      headingTwo: 2,
      headingThree: 1.375,
      headingFour: 1.125,
      subtitleOne: 1.125,
      subtitleTwo: 1,
      bodyOne: 1,
      bodyOneSecondary: 1,
      bodyTwo: 0.875,
      bodyTwoSecondary: 0.875,
      buttonOne: 1.1875,
      buttonTwo: 1,
      label: 0.875,
      overline: 0.875,
      caption: 0.875,
      tab: 0.625,
    },
    lineHeights: {
      headingOne: 3.375,
      headingTwo: 2.75,
      headingThree: 2,
      headingFour: 1.625,
      subtitleOne: 1.625,
      subtitleTwo: 1.5,
      bodyOne: 1.5,
      bodyOneSecondary: 1.5,
      bodyTwo: 1.25,
      bodyTwoSecondary: 1.25,
      buttonOne: 1.5,
      buttonTwo: 1.5,
      label: 1.25,
      overline: 1.25,
      caption: 1.25,
      tab: 0.75,
    },
    baseSize: 16,
  },
  fonts: {
    headings: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
    body: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
  },
  transitions: { defaultTime: '200ms' },
};
