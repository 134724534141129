import './index.css';
import './assets/fonts/fonts.css';

import React, { Suspense, useEffect, useMemo } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorState, LoadingIndicator, log } from '@manulife-web/shared-module';
import { CareCollectionsRoutesBrowserRouter } from '@leagueplatform/care-collections';
import { CareDiscoveryRoutesBrowserRouter } from '@leagueplatform/care-discovery';
import League, {
  LEAGUE_MODULE_NAMES,
  LeagueConfig,
} from '@leagueplatform/core';
import { useAuth0 } from '@auth0/auth0-react';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import { Away } from 'pages/Away';
import { BasePage } from './components/BasePage';
import Home from './pages/Home';
import { getConfig } from './config';

League.overwriteRoutePaths({
  [LEAGUE_MODULE_NAMES.assessment]: '/member/health-profile/questions',
  [LEAGUE_MODULE_NAMES.healthProfile]: '/member/health-profile',
});

log('test message');

export const AuthViewLogout = () => {
  const { logout } = useAuth0();
  React.useEffect(() => {
    logout({
      returnTo: window.location.origin,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingIndicator />;
};

export const AuthViewSignIn = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        maxWidth: '320px',
        flexDirection: 'column',
        gap: '12px',
        margin: '32px auto',
      }}
    >
      Log into Manulife to continue
      <button
        onClick={() => loginWithRedirect()}
        style={{
          cursor: 'pointer',
        }}
      >
        Login
      </button>
    </div>
  );
};

function useTheme(defaultTheme = 'manulife_light') {
  useEffect(() => {
    let activeTheme = localStorage.getItem('MANULIFE_ACTIVE_THEME');
    if (!activeTheme) {
      activeTheme = defaultTheme;
      localStorage.setItem('MANULIFE_ACTIVE_THEME', activeTheme);
    }
  }, [defaultTheme]);
}

function Loading() {
  return <p>loading...</p>;
}

const LeagueProviderWrapper = () => {
  const { getAccessTokenSilently } = useAuth0();
  const config: LeagueConfig = useMemo(
    () => getConfig(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  return (
    <League.LeagueProvider config={config}>
      <ErrorBoundary fallback={<ErrorState />}>
        <Suspense fallback={<LoadingIndicator />}>
          <Outlet />
          <ReactQueryDevtools />
        </Suspense>
      </ErrorBoundary>
    </League.LeagueProvider>
  );
};

const Layout = () => {
  const { isLoading, error, user, isAuthenticated } = useAuth0();

  useTheme();

  if (isLoading) {
    return (
      <div>
        <p>loading...</p>
      </div>
    );
  }

  if (!user || !isAuthenticated) {
    return <Navigate to="/sign-in" replace />;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return <LeagueProviderWrapper />;
};

const router = createBrowserRouter([
  {
    path: '/away',
    element: <Away />,
    loader: Loading,
  },
  {
    path: '/',
    element: <Layout />,
    loader: Loading,
    children: [
      {
        path: '/',
        element: <BasePage />,
        children: [
          { index: true, element: <Home /> },
          ...CareCollectionsRoutesBrowserRouter,
          ...CareDiscoveryRoutesBrowserRouter,
        ],
      },
      {
        path: '/sign-out',
        element: <AuthViewLogout />,
      },
    ],
  },
  {
    path: '/sign-in',
    element: <AuthViewSignIn />,
  },
]);

const App = () => <RouterProvider router={router} />;

export default App;
