import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';

export enum NavIds {
  HOME = 'HOME',
  JOURNEY = 'JOURNEY',
  CARE = 'GET_CARE',
  ABOUT_ME = 'ABOUT_ME',
  CARE_COLLECTIONS = 'CARE_COLLECTIONS',
  AWAY = 'AWAY',
}

export enum LinkType {
  NAV = 'navLink',
  LINK = 'link',
}

type NavItem = {
  to:
    | string
    | {
        search: string;
        pathname: string;
        state: unknown;
        hash: string;
        key?: string | undefined;
      };
};

export interface NavLink extends NavItem {
  message_id: NavIds;
  type: LinkType;
}

export const useNavLinksList = () => {
  const NavLinksList: NavLink[] = [
    {
      to: '/',
      message_id: NavIds.HOME,
      type: LinkType.NAV,
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.careDiscovery),
      message_id: NavIds.CARE,
      type: LinkType.NAV,
    },

    {
      to: getModulePath(LEAGUE_MODULE_NAMES.careCollections),
      message_id: NavIds.CARE_COLLECTIONS,
      type: LinkType.NAV,
    },
    {
      to: '/away',
      message_id: NavIds.AWAY,
      type: LinkType.NAV,
    },
  ];

  return {
    navLinks: NavLinksList,
  };
};
