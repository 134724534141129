import { Link } from 'react-router-dom';
import { useState } from 'react';

export const Away = () => {
  const [currentLang, setCurrentLang] = useState(document.documentElement.lang);

  const toggleLang = () => {
    const newLang = currentLang === 'fr' ? 'en' : 'fr';
    document.documentElement.lang = newLang;
    setCurrentLang(newLang);
  };

  return (
    <div>
      <p>just a page away from LeagueProvider</p>
      <Link to="/get-care">go to get care</Link>
      <button style={{ display: 'block' }} type="button" onClick={toggleLang}>
        Toggle lang
      </button>
      <span>Current Lang: {currentLang}</span>
    </div>
  );
};
