import * as React from 'react';

import { Box, HeadingText } from '@leagueplatform/genesis-core';

function Home() {
  return (
    <Box css={{ textAlign: 'center', paddingTop: '$two' }}>
      <Box css={{ marginY: '$four', marginX: '$auto' }}>
        <HeadingText size="lg" level="1">
          Welcome to Manulife
        </HeadingText>
      </Box>
    </Box>
  );
}

export default Home;
