import { Environment, LeagueConfig } from '@leagueplatform/core';
/**
 * @ts-ignore is used here due to @leagueplatform/legacy-locales being a
 * temporary measure that is served as a JS utility.
 *
 */
// @ts-ignore
import { legacyStrings } from '@leagueplatform/legacy-locales';
import { EN_STRING_OVERRIDES } from 'locales/en';
import { manulifeTheme } from 'theme/theme';
import { manulifeDarkTheme } from 'theme/ManulifeDark';
import ML_CHEVRON_BACK from './assets/ml_chevron_back.svg';
import ML_CHEVRON_FORWARD from './assets/ml_chevron_forward.svg';
import ML_CTA_INDICATOR from './assets/ml_cta_indicator.svg';

export const getConfig = (tokenGetter: () => Promise<string>): LeagueConfig => {
  const activeTheme = localStorage.getItem('MANULIFE_ACTIVE_THEME');
  const isManulifeThemeActive =
    activeTheme === 'manulife_dark' || 'manulife_light';

  const theme =
    isManulifeThemeActive && activeTheme === 'manulife_dark'
      ? manulifeDarkTheme
      : manulifeTheme;

  return {
    core: {
      clientId: 'manulife-web',
      auth: {
        getToken: tokenGetter,
      },
      analytics: {
        GAPropertyId: import.meta.env.VITE_GA_PROPERTY_ID,
        segmentKey: import.meta.env.VITE_SEGEMENT_KEY,
      },
      api: {
        wsUrl: import.meta.env.VITE_WS_API_URL as string,
        url: import.meta.env.VITE_API_URL as string,
        legacyRestApi: import.meta.env.VITE_LEGACY_REST_API_URL as string,
      },
      contentUrl: import.meta.env.VITE_CONTENT_URL as string,
      appEnvironment: import.meta.env.VITE_APP_ENV as Environment,
      customMethods: {
        handleLink: async (url) => {
          await window.open(url, '_blank', 'noreferrer');
        },
      },
      ui: {
        theme,
        themeOptions: {
          customIcons: {
            interfaceChevronLeft: ML_CHEVRON_BACK,
            interfaceChevronRight: ML_CHEVRON_FORWARD,
            interfaceCTAIndicator: ML_CTA_INDICATOR,
          },
        },
      },
      i18n: {
        strings: {
          default: { ...legacyStrings.default, ...EN_STRING_OVERRIDES },
          en: { ...legacyStrings.en, ...EN_STRING_OVERRIDES },
        },
      },
    },
    careDiscovery: {
      masonryAppId: {
        left: 'getCareWeb$root',
      },
      masonryAppVersion: '2.0',
    },
    healthJourney: {
      // headerConfig: { //commented out unless using local linking or until these changes are pushed to the current sdk version
      //   horizontalAlignment: 'start',
      //   isHeaderDescription: true,
      //   backgroundColor: '$surfaceBackgroundPrimary',
      // },
      tabs: ['activities', 'explore', 'history', 'progress'],
    },
    __internal: {
      shouldApplyCSSReset: false, // Manulife is embedded so we cannot apply a global reset
      shouldApplyLegacyGlobalStyles: false, // Manulife is embedded so we cannot apply a global reset
      shouldIgnoreDOMLangAttributeForLocale: false, // Manulife uses the `lang` attribute to set locale, so we don't want to ignore it
    },
  };
};

export const initializeNonSDKConfig = () => {};
