// Auto-generated file: Do not modify directly. Changes may be overwritten. Version: 1.1.0.

/*
 * Following tokens have been deprecated in version 1.1.0, please update your theme:
 *
 * Interactive/Focus has been split into two separate tokens. Please define 'Interactive/Focus Inner' and 'Interactive/Focus Outer' instead.
 * 'Interactive/Background Default' is deprecated. Please use 'Input/Background Default' instead.
 * 'Interactive/Background Disabled' is deprecated. Please use 'Input/Background Disabled' instead.
 * 'Interactive/Background Hovered' is deprecated. Please use 'Input/Background Hovered' instead.
 * 'Interactive/Background Pressed' is deprecated. Please use 'Input/Background Pressed' instead.
 * 'Interactive/Border Default' is deprecated. Please use 'Input/Border Default' instead.
 * 'Interactive/Border Hovered' is deprecated. Please use 'Input/Border Hovered' instead.
 * 'Interactive/Border Disabled' is deprecated. Please use 'Input/Border Disabled' instead.
 * 'Interactive/Border Critical Default' is deprecated. Please use 'Input/Border Critical' instead.
 * 'Pill' is deprecated. Please use 'Circle' instead.
 */

import type { GDSTheme } from '@leagueplatform/genesis-core';

export const manulifeDarkTheme: GDSTheme = {
  colors: {
    surface: {
      background: {
        primary: '#000000FF',
        secondary: '#1C1C1EFF',
        tertiary: '#1C1C1EFF',
      },
      card: {
        primary: '#1C1C1EFF',
        secondary: '#1C1C1EFF',
        disabled: '#48484AFF',
      },
    },
    onSurface: {
      text: {
        primary: '#FFFFFFFF',
        subdued: '#C2C3C9FF',
        reversed: '#C2C3C9FF',
        critical: '#FF7769FF',
        success: '#06874EFF',
        warning: '#FFFFFFFF',
      },
      border: { subdued: '#636366FF', default: '#C2C3C9FF' },
      icon: { primary: '#DFE0E2FF' },
    },
    interactive: {
      action: {
        primary: '#06874EFF',
        hovered: '#046138FF',
        pressed: '#004427FF',
        subdued: '#8D8D93FF',
        disabled: '#77777AFF',
      },
      icon: { default: '#FFFFFFFF', disabled: '#8E90A2FF' },
      background: {
        default: '#000000FF',
        disabled: '#2C2C2EFF',
        hovered: '#1C1C1EFF',
        pressed: '#1C1C1EFF',
      },
      border: {
        default: '#8E8E93FF',
        hovered: '#046138FF',
        disabled: '#474747FF',
        critical: { default: '#EF6782FF' },
      },
      focus: { inner: '#C1D8F7FF', outer: '#C1D8F7FF' },
    },
    critical: {
      background: { subdued: '#5E0407FF', secondary: '#000000FF' },
      border: { default: '#F8D7D9FF' },
      icon: '#F491A5FF',
    },
    warning: {
      background: { subdued: '#A75900FF', secondary: '#000000FF' },
      border: { default: '#FBE9C6FF' },
      icon: '#06874EFF',
    },
    success: {
      background: { subdued: '#06874EFF', secondary: '#ACE5C4FF' },
      border: { default: '#ACE5C4FF' },
      icon: '#004427FF',
    },
    highlight: {
      background: { subdued: '#1C1C1EFF', secondary: '#000000FF' },
      border: { default: '#94ACEFFF' },
      icon: '#76B0FFFF',
    },
    decorative: {
      brand: {
        primary: {
          darkest: '#836AA6FF',
          dark: '#6F5693FF',
          default: '#836AA6FF',
          bright: '#D4D2E9FF',
          brightest: '#636366FF',
          pastel: '#1C1C1EFF',
        },
        secondary: {
          darkest: '#0B9189FF',
          dark: '#05B2A7FF',
          default: '#06C7BAFF',
          bright: '#68E7DFFF',
          brightest: '#9DF3EDFF',
          pastel: '#C5F4F1FF',
        },
        tertiary: {
          darkest: '#CCFF9AFF',
          dark: '#56FE64FF',
          default: '#18F2A4FF',
          bright: '#12B67BFF',
          brightest: '#0C7952FF',
          pastel: '#063D29FF',
        },
      },
    },
    primary: {
      background: {
        default: '#06874EFF',
        hovered: '#046138FF',
        pressed: '#004427FF',
        critical: {
          default: '#CB2A32FF',
          hovered: '#E13557FF',
          pressed: '#E75A76FF',
        },
      },
      text: { default: '#FFFFFFFF', critical: { default: '#FFFFFFFF' } },
    },
    secondary: {
      background: {
        default: '#FFFFFF00',
        hovered: '#333333FF',
        pressed: '#1B1B1BFF',
      },
      text: { default: '#CAEED9FF', hovered: '#FFFFFFFF' },
      border: { default: '#06874EFF' },
    },
    tertiary: {
      background: {
        default: '#FFFFFF00',
        hovered: '#F0F0F0FF',
        pressed: '#E1E1E1FF',
      },
      text: { default: '#C1C1C1FF', hovered: '#1E1E1EFF' },
      border: { default: '#C1C1C1FF' },
    },
    input: {
      border: {
        success: '#B3CE84FF',
        warning: '#FFF0A2FF',
        default: '#8E8E93FF',
        hovered: '#046138FF',
        disabled: '#474747FF',
        critical: '#EF6782FF',
      },
      background: {
        default: '#000000FF',
        disabled: '#2C2C2EFF',
        hovered: '#1C1C1EFF',
        pressed: '#1C1C1EFF',
      },
    },
  },
  shadows: {
    card: { x: 0, y: 2, blur: 6, spread: 0, color: '#FFFFFF14' },
    dropdown: { x: 0, y: 4, blur: 8, spread: 0, color: '#FFFFFF1A' },
  },
  typography: {
    headingOne: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 40,
      fontSize: 32,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingTwo: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 32,
      fontSize: 24,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingThree: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 28,
      fontSize: 20,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingFour: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 24,
      fontSize: 18,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    subtitleOne: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    subtitleTwo: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyOne: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyOneSecondary: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyTwo: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyTwoSecondary: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    buttonOne: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    buttonTwo: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    label: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 500,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    overline: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0.6000000238418579,
      paragraphSpacing: 0,
      textCase: 'UPPER',
      textDecoration: 'none',
    },
    caption: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    tab: {
      fontFamily: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 12,
      fontSize: 10,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
  },
  spacing: {
    none: 0,
    quarter: 4,
    half: 8,
    threeQuarters: 12,
    one: 16,
    oneAndHalf: 24,
    two: 32,
    twoAndHalf: 40,
    three: 48,
    four: 64,
    five: 80,
  },
  borderWidths: { thin: 1, thick: 2, outerFocus: 3, innerFocus: 3 },
  borderRadii: {
    none: 0,
    thin: 1,
    thick: 2,
    small: 3,
    medium: 6,
    large: 9,
    extraLarge: 12,
    huge: 20,
    circle: 99999,
    pill: 99999,
    button: 99999,
    inputField: 6,
  },
  zIndices: {
    default: 1,
    sticky: 100,
    dropdown: 4000,
    tooltip: 5000,
    modal: 8000,
    toast: 9000,
  },
  rem: {
    fontSizes: {
      headingOne: 2,
      headingTwo: 1.5,
      headingThree: 1.25,
      headingFour: 1.125,
      subtitleOne: 1,
      subtitleTwo: 0.875,
      bodyOne: 1,
      bodyOneSecondary: 1,
      bodyTwo: 0.875,
      bodyTwoSecondary: 0.875,
      buttonOne: 1,
      buttonTwo: 0.875,
      label: 0.75,
      overline: 0.75,
      caption: 0.75,
      tab: 0.625,
    },
    lineHeights: {
      headingOne: 2.5,
      headingTwo: 2,
      headingThree: 1.75,
      headingFour: 1.5,
      subtitleOne: 1.5,
      subtitleTwo: 1.25,
      bodyOne: 1.5,
      bodyOneSecondary: 1.5,
      bodyTwo: 1.25,
      bodyTwoSecondary: 1.25,
      buttonOne: 1.5,
      buttonTwo: 1.25,
      label: 1,
      overline: 1,
      caption: 1,
      tab: 0.75,
    },
    baseSize: 16,
  },
  fonts: {
    headings: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
    body: "'Manulife JH Sans', Helvetica, Arial, sans-serif",
  },
  transitions: { defaultTime: '200ms' },
};
